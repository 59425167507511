import React from 'react';
import Helmet from 'react-helmet';

import Layout from 'components/Layout';
import Container from 'components/Container';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import pdbot from 'assets/images/pd_bot.png';

const NotFoundPage = () => {
    const data = useStaticQuery(graphql`
        query {
            placeholderImage: file(relativePath: { eq: "pd_bot.png" }) {
                childImageSharp {
                    fluid(maxWidth: 600) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
        }
    `);
    return (
        <Layout pageName="404">
            <Helmet>
                <title>Sackgasse</title>
            </Helmet>
            <Container>
                <div className="error_container">
                    <h1>Hier ist eine Sackgasse</h1>
                    <p>Du versuchst gerade etwas aufzurufen, was wir nicht finden können.</p>
                    <Img fluid={data.placeholderImage.childImageSharp.fluid} />
                </div>
            </Container>
        </Layout>
    );
};

export default NotFoundPage;
